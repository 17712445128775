@import "colors";


body {
  background-color: $grey-light !important;
}

a {
  text-decoration: none;
  color: #000;
}

.MuiCard-root {
  margin: .5rem;
  border-radius: .5rem !important;
}

.MuiCardHeader-root {
  font-weight: bold !important;
  text-transform: uppercase !important;
  align-items: stretch !important;

  .MuiCardHeader-title {
    font-weight: bold !important;
  }

}


.pricePaper {
  padding: 1rem;

}

.bgRed {
  background-color: $red400 !important;
  color: $red50 !important;
}

.bgBlueGrey {
  background-color: $blue-grey400 !important;
  color: $blue-grey50 !important;
}

.bgBlue {
  background-color: $blue400 !important;
  color: $blue50 !important;
}

.bgBlack {
  background-color: $sols-black !important;
  color: white !important;
}

.bgGrey {
  background-color: $sols-grey !important;
  color: white !important;
}

.bgTeal {
  background-color: $teal400 !important;
  color: $teal50 !important;
}


//.MuiButton-root {
//  border-radius: .5rem !important;
//
//}

//$sols-pillar: #DB4B4B;
//

//
//
//// some constants
//$app-grey: #555555;
//$app-transition: .2s ease-in-out;
//// breakpoints
//$bp-mobile: 480px;
//$bp-tablet: 768px;
//$bp-desktop: 1080px;
//
//*,
//*:after,
//*:before {
//  box-sizing: inherit;
//}
//
//
//body {
//  margin: 0;
//  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
//  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
//  sans-serif;
//  -webkit-font-smoothing: antialiased;
//  -moz-osx-font-smoothing: grayscale;
//  color: #000000;
//}
//
//
//label {
//  font-weight: 600;
//}
//
//a {
//  text-decoration: none;
//  color: #000000;
//}
//
//
//code {
//  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
//  monospace;
//}
//
//.site-content {
//  padding: 24px;
//  margin: 10px;
//  min-height: 280px;
//  background: #fff;
//}
//
//.site-content-transparent {
//  padding: 24px;
//  margin: 10px;
//  min-height: 280px;
//}
//
//html {
//  box-sizing: border-box;
//}
//
//
//blockquote {
//  border-left: 0.3rem solid #d1d1d1;
//  margin-left: 0;
//  margin-right: 0;
//  padding: 1rem 1.5rem;
//}
//
//blockquote *:last-child {
//  margin-bottom: 0;
//}
//
//
//code {
//  background: #f4f5f6;
//  border-radius: .4rem;
//  margin: 0 .2rem;
//  padding: .2rem .5rem;
//  white-space: nowrap;
//}
//
//pre {
//  background: #f4f5f6;
//  border-left: 0.3rem solid #F8BE15;
//  overflow-y: hidden;
//}
//
//pre > code {
//  border-radius: 0;
//  display: block;
//  padding: 1rem 1.5rem;
//  white-space: pre;
//}
//
//hr {
//  border: 0;
//  border-top: 0.1rem solid #f4f5f6;
//  margin: 3.0rem 0;
//}
//
//input[type='color'],
//input[type='date'],
//input[type='datetime'],
//input[type='datetime-local'],
//input[type='email'],
//input[type='month'],
//input[type='number'],
//input[type='password'],
//input[type='search'],
//input[type='tel'],
//input[type='text'],
//input[type='url'],
//input[type='week'],
//input:not([type]),
//textarea,
//select {
//  -webkit-appearance: none;
//  background-color: transparent;
//  border: 0.1rem solid #d1d1d1;
//  border-radius: .4rem;
//  box-shadow: none;
//  box-sizing: inherit;
//  padding: .6rem 1.0rem .7rem;
//  width: 100%;
//}
//
//input[type='color']:focus,
//input[type='date']:focus,
//input[type='datetime']:focus,
//input[type='datetime-local']:focus,
//input[type='email']:focus,
//input[type='month']:focus,
//input[type='number']:focus,
//input[type='password']:focus,
//input[type='search']:focus,
//input[type='tel']:focus,
//input[type='text']:focus,
//input[type='url']:focus,
//input[type='week']:focus,
//input:not([type]):focus,
//textarea:focus,
//select:focus {
//  border-color: #F8BE15;
//  outline: 0;
//}
//
//select {
//  background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 8" width="30"><path fill="%23000000" d="M0,0l6,8l6-8"/></svg>') center right no-repeat;
//  padding-right: 3.0rem;
//}
//
//select:focus {
//  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 8" width="30"><path fill="%23000000" d="M0,0l6,8l6-8"/></svg>');
//}
//
//select[multiple] {
//  background: none;
//  height: auto;
//}
//
//textarea {
//  min-height: 6.5rem;
//}
//
//label,
//legend {
//  display: block;
//  font-weight: 700;
//  margin-bottom: .5rem;
//}
//
//fieldset {
//  border-width: 0;
//  padding: 0;
//}
//
//input[type='checkbox'],
//input[type='radio'] {
//  display: inline;
//}
//
//.label-inline {
//  display: inline-block;
//  font-weight: normal;
//  margin-left: .5rem;
//}
//
//.container {
//  margin: 0 auto;
//  max-width: 112.0rem;
//  padding: 0 2.0rem;
//  position: relative;
//  width: 100%;
//}
//
//.row {
//  display: flex;
//  flex-direction: column;
//  padding: 0;
//  width: 100%;
//}
//
//.row.row-no-padding {
//  padding: 0;
//}
//
//.row.row-no-padding > .column {
//  padding: 0;
//}
//
//.row.row-wrap {
//  flex-wrap: wrap;
//}
//
//.row.row-top {
//  align-items: flex-start;
//}
//
//.row.row-bottom {
//  align-items: flex-end;
//}
//
//.row.row-center {
//  align-items: center;
//}
//
//.row.row-stretch {
//  align-items: stretch;
//}
//
//.row.row-baseline {
//  align-items: baseline;
//}
//
//.row .column {
//  display: block;
//  flex: 1 1 auto;
//  margin-left: 0;
//  max-width: 100%;
//  width: 100%;
//}
//
//.row .column.column-offset-10 {
//  margin-left: 10%;
//}
//
//.row .column.column-offset-20 {
//  margin-left: 20%;
//}
//
//.row .column.column-offset-25 {
//  margin-left: 25%;
//}
//
//.row .column.column-offset-33, .row .column.column-offset-34 {
//  margin-left: 33.3333%;
//}
//
//.row .column.column-offset-40 {
//  margin-left: 40%;
//}
//
//.row .column.column-offset-50 {
//  margin-left: 50%;
//}
//
//.row .column.column-offset-60 {
//  margin-left: 60%;
//}
//
//.row .column.column-offset-66, .row .column.column-offset-67 {
//  margin-left: 66.6666%;
//}
//
//.row .column.column-offset-75 {
//  margin-left: 75%;
//}
//
//.row .column.column-offset-80 {
//  margin-left: 80%;
//}
//
//.row .column.column-offset-90 {
//  margin-left: 90%;
//}
//
//.row .column.column-10 {
//  flex: 0 0 10%;
//  max-width: 10%;
//}
//
//.row .column.column-20 {
//  flex: 0 0 20%;
//  max-width: 20%;
//}
//
//.row .column.column-25 {
//  flex: 0 0 25%;
//  max-width: 25%;
//}
//
//.row .column.column-33, .row .column.column-34 {
//  flex: 0 0 33.3333%;
//  max-width: 33.3333%;
//}
//
//.row .column.column-40 {
//  flex: 0 0 40%;
//  max-width: 40%;
//}
//
//.row .column.column-50 {
//  flex: 0 0 50%;
//  max-width: 50%;
//}
//
//.row .column.column-60 {
//  flex: 0 0 60%;
//  max-width: 60%;
//}
//
//.row .column.column-66, .row .column.column-67 {
//  flex: 0 0 66.6666%;
//  max-width: 66.6666%;
//}
//
//.row .column.column-75 {
//  flex: 0 0 75%;
//  max-width: 75%;
//}
//
//.row .column.column-80 {
//  flex: 0 0 80%;
//  max-width: 80%;
//}
//
//.row .column.column-90 {
//  flex: 0 0 90%;
//  max-width: 90%;
//}
//
//.row .column .column-top {
//  align-self: flex-start;
//}
//
//.row .column .column-bottom {
//  align-self: flex-end;
//}
//
//.row .column .column-center {
//  align-self: center;
//}
//
//@media (min-width: 40rem) {
//  .row {
//    flex-direction: row;
//    margin-left: -1.0rem;
//    width: calc(100% + 2.0rem);
//  }
//
//  .row .column {
//    margin-bottom: inherit;
//    padding: 0 1.0rem;
//  }
//}
//
//
//a:focus, a:hover {
//  color: #000000;
//}
//
//dl,
//ol,
//ul {
//  list-style: none;
//  margin-top: 0;
//  padding-left: 0;
//}
//
//dl dl,
//dl ol,
//dl ul,
//ol dl,
//ol ol,
//ol ul,
//ul dl,
//ul ol,
//ul ul {
//  margin: 1.5rem 0 1.5rem 3.0rem;
//}
//
//ol {
//  list-style: decimal inside;
//}
//
//ul {
//  list-style: circle inside;
//}
//
//
//dd,
//dt,
//li {
//  margin-bottom: 1.0rem;
//}
//
//fieldset,
//input,
//select,
//textarea {
//  margin-bottom: 1.5rem;
//}
//
//blockquote,
//dl,
//figure,
//form,
//ol,
//p,
//pre,
//table,
//ul {
//  margin-bottom: 2.5rem;
//}
//
//table {
//  align-items: center;
//  border-spacing: 0;
//  display: block;
//  overflow-x: auto;
//  text-align: left;
//  width: 100%;
//  border: 1px solid $sols-grey;
//}
//
//td,
//th {
//  border-bottom: 0.1rem solid #e1e1e1;
//
//}
//
//td:first-child,
//th:first-child {
//  padding-left: 1rem;
//}
//
//td:last-child,
//th:last-child {
//  padding-right: 0;
//}
//
//
//th {
//  background-color: #f2f2f2
//}
//
//
//.text-center {
//  text-align: center;
//}
//
//// Dashboard
//.shift-right{
//  text-align: right;
//}
//
//.shift-left{
//  text-align: left;
//}
//
//.text-color-green{
//	color:$green;
//}
//.text-color-red{
//	color:$red;
//}
//.text-color-yellow{
//	color:$yellow;
//}
//.text-color-blue{
//	color:$blue;
//}
//.text-color-teal{
//	color:$teal;
//}
//.text-color-purple{
//	color:$purple;
//}
//.text-color-grey{
//	color:$grey;
//}
//.text-color-green-dark{
//	color:$green-dark;
//}
//.text-color-red-dark{
//	color:$red-dark;
//}
//.text-color-yellow-dark{
//	color:$yellow-dark;
//}
//.text-color-blue-dark{
//	color:$blue-dark;
//}
//.text-color-teal-dark{
//	color:$teal-dark;
//}
//.text-color-purple-dark{
//	color:$purple-dark;
//}
//.text-color-grey-dark{
//	color:$grey-dark;
//}
//
//
//// background colors
//.bg-color-green{
//	background-color:$green;
//}
//.bg-color-red{
//	background-color:$red;
//}
//.bg-color-yellow{
//	background-color:$yellow;
//}
//.bg-color-blue{
//	background-color:$blue;
//}
//.bg-color-teal{
//	background-color:$teal;
//}
//.bg-color-purple{
//	background-color:$purple;
//}
//.bg-color-grey{
//	background-color:$grey;
//}
//.bg-color-green-dark{
//	background-color:$green-dark;
//}
//.bg-color-red-dark{
//	background-color:$red-dark;
//}
//.bg-color-yellow-dark{
//	background-color:$yellow-dark;
//}
//.bg-color-blue-dark{
//	background-color:$blue-dark;
//}
//.bg-color-teal-dark{
//	background-color:$teal-dark;
//}
//.bg-color-purple-dark{
//	background-color:$purple-dark;
//}
//.bg-color-grey-dark{
//	background-color:$grey-dark;
//}
//
//@media (min-width: 40rem) {
//  table {
//    display: table;
//    overflow-x: auto;
//  }
//}
//
//b,
//strong {
//  font-weight: bold;
//}
//
//p {
//  margin-top: 0;
//}
//
//
//img {
//  max-width: 100%;
//}
//
//.clearfix:after {
//  clear: both;
//  content: ' ';
//  display: table;
//}
//
//.float-left {
//  float: left;
//}
//
//.float-right {
//  float: right;
//}
//
///*# sourceMappingURL=milligram.css.map */


